import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";

const Mainslidersettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="quikjets-banner">
          <div className="quikjets-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item empty-legs-flight">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Rentpartner Residential </h2>
                            <p> 100 Percent Verified Branded Residential Property Listing only.
                                Rentpartners is the world only Digital Pnpm run latform were owner can list
                                the preference of Tenants and much more benefits offered like Rentpay, 
                                 Online Rental Agreement Services, Property, Tenancy and Owner Background 
                                Verification Services.
                                
                            </p>

                         {/* <div className="action-buttons">
                              <Link to="" className="btn btn-standard">
                               
                              </Link>
                             </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item world-class-service">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Rentpartner Commercial </h2>
                            <p>Rentpartners Commercial Digital Platform offers Latest Inventory of Workspaces,
                              Business Centres, Offices, Services and Standalone building at Attractive and 
                              Competitive prices.
                            </p>

                            {/*  <div className="action-buttons">
                              <Link to="" className="btn btn-standard">
                               
                              </Link> 
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item memership">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Rentpartner Hospitality</h2>
                            <p> Rentpartners Hospitality Digital Platform offers a Large Inventory
                               Stays, Lodges, Motels, 7 Star, 5 Star, 3 Star, Resorts at very Competitive prices.
                             
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
               {/*} <div className="item world-class">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2></h2>
                            <p>
                             
                            </p>
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                          </div>*/}
               {/* <!--Slider Item ends--> */}

                <div className="item world">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2>Rentpartner Industrial</h2>
                            <p> Rentpartners Industrial Digital Platform in association 
                              with its network offers Industrial Sheds, Parks, Auto Parks,
                              Pharma Parks, Textile Parks, Aero Parks and Bio Parks at very Competitive prices.
                            
                            </p>
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                 {/* <!--Slider Item ends--> */}

                 <div className="item slider">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            <h2> Rentpartner Healthcare</h2>
                            <p> Rentpartners Healthcare Digital Platform offers you a Large Inventory of Hospitals,
                               Clinics, Pharma, Superspeciality Hospitals, Health City at very Attractive prices.
                            
                            </p>
                            </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                 {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
