import React from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import './Footer.scss'
export default class Footer extends React.Component {

    render() {
        return (
           
            <div className="footer">
                <div className="container mx-auto">
                    <div className="content1">
                        <p>Rentpartners is the world's first<br></br> digital platform for renting needs<br></br>
                         in the sectors of residential,<br></br> commercial, hospitality, industrial, 
               <br></br> healthcare and aviation</p> <br></br>
                    </div>

                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <div className="social-media">
                                <h3>Follow Us</h3>
                                <ul>
                                    <li><Link><i className="flaticon-facebook-3" /></Link></li>
                                    <li><Link><i className="flaticon-instagram" /></Link></li>
                                    <li><Link><i className="flaticon-twitter" /></Link></li>
                                    <li><Link><i className="flaticon-youtube-1" /></Link></li>
                                </ul>
                            </div>
                        </Col>

                       {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <div className="quick-links">
                                <h3>Rent</h3>
                                <ul>
                                    <li><Link to="/furniture">Furniture</Link></li>
                                    <li><Link to="/bundles">Bundles</Link></li>
                                    <li><Link>Living Room</Link></li>
                                    <li><Link>Bed Room</Link></li>
                                    <li><Link>Dining Room</Link></li>
                                    <li><Link>Kid’s Room</Link></li>
                                    <li><Link>Kitchen</Link></li>
                                </ul>
                            </div>
                        </Col> */}
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <div className="quick-links">
                                <h3>Help</h3>
                                <ul>
                                    <li><Link to="/how-it-works">How it works</Link></li>
                                    <li><Link>Testimonials</Link></li>
                                    <li><Link>FAQ’s</Link></li>
                                    <li><Link>Return Policy</Link></li>
                                    <li><Link>Contact us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                            <div className="quick-links">
                                <h3>About</h3>
                                <ul>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link>Careers</Link></li>
                                    <li><Link to="/TermsAndCondition">Terms and Conditions</Link></li>
                                    <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                                    <li><Link>Copyright Policy</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}