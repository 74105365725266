import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import './AboutUs.scss';
import QuickLinks from '../QuickLinks/QuickLinks';


class AboutUs extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="about-banners">
                    <img src={require('../../assets/images/image3.jpg')} alt="About Us" />
                </section>
                {/* page banners */}

                <div className="inner-pages padding-40 top-left-wave light-bg">
                    <div className="container mx-auto">

                     

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <h3>About Us</h3>
                                <p> Rentpartners is the world's first Digital Platform for renting needs in 
            the Sectors of Residential, Commercial, Hospitality, Industrial, Healthcare
            and Aviation. Rentpartners with its Worldwide network has got access for some 
            of the finest Properties and Brands. Rentpartners offers Digital Payments as Rentpay,
            Online Rental Management Services and Background Verification Services for Properties,
            Owners and Tenants. </p>
                                
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <img src={require('../../assets/images/image2.jpg')} alt="About Us" />
                            </Col>
                        </Row>
                    </div>
                </div>


            </React.Fragment >
        )





    }
}
export default withRouter(AboutUs)


