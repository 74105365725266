import React from "react";
import { Link } from "react-router-dom";
import BundlesHome from "./BundlesHome/BundlesHome";
import "./Home.scss";
import HomeSlider from "./HomeSlider/HomeSlider";
import Testimonials from "./Testimonials/Testimonials";
import StagingDepotCommitment from "./StagingDepotCommitment/StagingDepotCommitment";
import mainlogo from "../../assets/images/main-slider/slider24.jpg";
import mainlogo1 from "../../assets/images/main-slider/residential.jpg";
import mainlogo2 from "../../assets/images/main-slider/commercial.jpg";
import mainlogo3 from "../../assets/images/main-slider/industries.jpg";
import mainlogo4 from "../../assets/images/main-slider/hotel.jpg";
import mainlogo5 from "../../assets/images/main-slider/healthcare.jpg";
import logo from "../../assets/images/logos/hira.jpg";
import logo1 from "../../assets/images/logos/century2.jpg";
import logo2 from "../../assets/images/logos/Embassy.png";
import logo3 from "../../assets/images/logos/brigade.png";
import logo4 from "../../assets/images/logos/prestige.png";
import logo5 from "../../assets/images/logos/assetz.png";
import logo6 from "../../assets/images/logos/DLF.png";
import logo7 from "../../assets/images/logos/Rustomjee2.jpg";
import logo8 from "../../assets/images/logos/oberoi.png";
import logo9 from "../../assets/images/logos/arvind2.png";
import logo10 from "../../assets/images/logos/adarsh.jpg";
import logo11 from "../../assets/images/logos/goyal and co.jpg";
import logo12 from "../../assets/images/logos/Puravankara.jpg";
import logo13 from "../../assets/images/logos/Godrej.png";
import logo14 from "../../assets/images/logos/Lodha.png";
import logo15 from "../../assets/images/logos/pride.png";
import logo16 from "../../assets/images/logos/vaishnavi.png";
import logo17 from "../../assets/images/logos/salarpuria.png";
import logo18 from "../../assets/images/logos/mahindra.jpg";
import logo19 from "../../assets/images/logos/aparna.jpg";
import logo20 from "../../assets/images/logos/phoenix2.png";
import logo21 from "../../assets/images/logos/LandT.png";
import logo22 from "../../assets/images/logos/tata.png";
import logo23 from "../../assets/images/logos/kalpataru.jpg";
import logo24 from "../../assets/images/logos/Radiance.jpg";
import logo25 from "../../assets/images/logos/akshaya.jpg";
import logo26 from "../../assets/images/logos/ahad.png";
import logo27 from "../../assets/images/logos/Lulu.png";
import logo28 from "../../assets/images/logos/confident.png";
import logo29 from "../../assets/images/logos/NCC.jpg";
import logo30 from "../../assets/images/logos/aratt.png";
import logo31 from "../../assets/images/logos/ansal.jpg";
import { Row, Col} from "antd";

{/*import image from "../../assets/images/main-slider/slider6.jpg";*/}
{/*import image from "../assets/images/main-slider/b5.jpg";*/}



export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        
        <HomeSlider />
        {/* main slider ends */}

        {/* home links section starts */}
        <section className="empty-legs-flight-cost">
          <div className="container mx-auto">
            <h2>About Us </h2>
            <p>
            Rentpartners is the world's first Digital Platform for renting needs <br></br> in 
            the sectors of Residential, Commercial, Hospitality, Industrial,<br></br> Healthcare
            and Aviation. Rentpartners with its Worldwide network <br></br> has got access for some 
            of the finest Properties and Brands. <br></br>Rentpartners offers Digital payments as Rentpay,
            Online Rental <br></br> Management Services and Background Verification Services for <br></br>Properties,
            Owners and Tenants.
            </p>
           {/* <p>
              If you are flexible enough with your private flight dates and
              time, and you are seeking for a one way flight, chances are you
              will find an empty leg flight matching your needs on the
              QuikjetsWW website. All the empty leg offers listed on our website
              have a start and an end date and time, this is the timeframe
              during which the empty leg flight is valid and available for
              charter. After its end date, the private jet will anyway have to
              takeoff to its next destination, to perform its next chartered
              flight.
            </p>
            <p>
              If the initial empty leg offer does not match perfectly with your
              travel needs but the time-frame corresponds to your flight dates,
              make sure to contact your QuikjetsWW Private Aviation Advisor to
              see if there is any possibility to re-route the empty leg flight.
              In some cases, the initial destination of the empty leg flight is
              not the destination you are looking after but is close enough to
              have the business aircraft re-routed. Let’s say you need a private
              plane departing from New-York to Paris and there is a private
              aircraft in New-York flying to London empty for repositioning. By
              modifying slightly the empty leg initial flight plan, you can
              still save up to 50% on your private jet flight cost, compared to
              a regular jet charter service.
            </p>
            <p>
              However, if your private jet travel needs are not flexible or if
              you are looking for a round trip flight, you might consider using
              QuikjetsWW on-demand private jet services.
            </p>
            <p>
              On this page you will find exclusive empty leg flights on all
              aircraft types, from Turboprops to heavy jets or airliners, for
              small regional flights to transcontinental flights. If none of the
              empty leg flights below are matching your itineraries please
              contact your Private Aviation Advisor who will search the market
              for you.
           </p> */}
          </div>
      {/*<div className="right-section">
                    <div className="image-jpg">
                      
                        <img src={image} alt="Staging Depot" />{" "}
                      
                    </div>
          </div> */}
    <Col
    xs={{ span: 24, order: 1 }}
    sm={{ span: 24, order: 1 }}
    md={{ span: 4, order: 1 }}
    lg={{ span: 4, order: 1 }}
    >
    <div className="left-section">
      <div className="image">
        <Link to="/">
          <img src={mainlogo} alt="Staging Depot" />{" "}
        </Link>
      </div>
    </div>
       </Col> 

       <div className="left image">
      <div className="image1">
        <Link to="/">
          <img src={mainlogo1} alt="Staging Depot1" />{" "}
        </Link>
      </div>
    </div>
       
       
       <div className="empty-legs">
          <div className="container mx-auto">
            <h2>Residential </h2>
            <p>Over 20 million plus Verified Listings of Luxury Apartments, Villas,
               Villaments, Bungalows, Townships at one click.</p>
               </div>
               </div>

             <div className="resfurn">
             <div className="furnished">
               <img src={require('../../assets/images/furnished.jpg')} alt="google signin" />
              </div>
               <div class="text-block">
                 <div class="furn">
            <p1>Furnished</p1>
              </div>
               </div>
             
             </div> 

        <div className="resunfurn">
        <div className="unfurnished">
               <img src={require('../../assets/images/unfurnished.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="unfurn">
            <p2>Unfurnished</p2>
              </div>
               </div>
             
        </div> 

        <div className="resserviced">
        <div className="services">
               <img src={require('../../assets/images/serviced.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="servi">
            <p2>Serviced</p2>
              </div>
               </div>
             
        </div> 

        <div className="reslux">
        <div className="luxury">
               <img src={require('../../assets/images/luxury.png')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="lux">
            <p2>Luxury</p2>
              </div>
               </div>
             
        </div> 

               <div className="left image">
      <div className="image2">
        <Link to="/">
          <img src={mainlogo2} alt="Staging Depot1" />{" "}
        </Link>
      </div>
    </div>

            
               <div className="empty-legs1">
          <div className="container mx-auto">
            <h2>Commercial </h2>
            <p>Ready-to-move in Offices, Workspaces, Serviced Offices and Standalone <br></br>
              Offices.</p>
               </div>
               </div>

               <div className="commbusi">
        <div className="business">
               <img src={require('../../assets/images/business1.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="busi">
            <p2>Business<br></br>Centres</p2>
              </div>
               </div>
             
        </div> 

        <div className="commwork">
        <div className="workfurn">
               <img src={require('../../assets/images/workspace furnished.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="workfur">
            <p2>Workspaces<br></br>Furnished</p2>
              </div>
               </div>
             
        </div> 

        <div className="commworkun">
        <div className="workunfurn">
               <img src={require('../../assets/images/workspace unfurnished.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="workunfur">
            <p2>Workspaces<br></br>Unfurnished</p2>
              </div>
               </div>
             
        </div> 

        <div className="commreadyto">
        <div className="readytomove">
               <img src={require('../../assets/images/readytomove.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="readyto">
            <p2>Ready to move<br></br>Offices</p2>
              </div>
               </div>
             
        </div> 

        <div className="communder">
        <div className="undercon">
               <img src={require('../../assets/images/undercons.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="underco">
            <p2>Underconstruction<br></br>Offices</p2>
              </div>
               </div>
             
        </div> 

        <div className="commstand">
        <div className="standalone">
               <img src={require('../../assets/images/standalone.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="stand">
            <p2>Stand alone<br></br>Offices</p2>
              </div>
               </div>
             
        </div> 
              
               <div className="left image">
      <div className="image3">
        <Link to="/">
          <img src={mainlogo3} alt="Staging Depot1" />{" "}
        </Link>
      </div>
    </div>

    <div className="empty-legs2">
          <div className="container mx-auto">
            <h2>Industrial </h2>
            <p>State of the art Industrial Parks, Auto Parks, Pharma Parks,
              Aero Parks ready fitout.</p>
               </div>
               </div>

               <div className="indushed">
        <div className="shedindus">
               <img src={require('../../assets/images/shed.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="shed">
            <p2>Shed</p2>
              </div>
               </div>
        </div>

               <div className="indufactory">
        <div className="factoryindus">
               <img src={require('../../assets/images/factory.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="factory">
            <p2>Factory</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="induauto">
        <div className="autoindus">
               <img src={require('../../assets/images/autoparks.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="auto">
            <p2>Auto Parks</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="induaero">
        <div className="aeroindus">
               <img src={require('../../assets/images/aeropark.jpeg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="auto">
            <p2>Aero Parks</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="induaero">
        <div className="aeroindus">
               <img src={require('../../assets/images/aeropark.jpeg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="aero">
            <p2>Aero Parks</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="indubusi">
        <div className="busiindus">
               <img src={require('../../assets/images/businesspark.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="business">
            <p2>Business Parks</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="indupharma">
        <div className="pharmaindus">
               <img src={require('../../assets/images/pharmapark.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="pharma">
            <p2>Pharma Parks</p2>
              </div>
               </div>
             
             
        </div> 

        <div className="induRD">
        <div className="RDindus">
               <img src={require('../../assets/images/R and D.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="RD">
            <p2>Research and<br></br> Development</p2>
              </div>
               </div>
             
             
        </div> 

               <div className="left image">
      <div className="image4">
        <Link to="/">
          <img src={mainlogo4} alt="Staging Depot1" />{" "}
        </Link>
      </div>
    </div>

    <div className="empty-legs3">
          <div className="container mx-auto">
            <h2>Hospitality </h2>
            <p>Oyo stays, 7 Star, 5 Star, Buisness Hotels, Motels and Resorts ready to <br></br> occupy.</p>
               </div>
               </div>
               
     <div className="hospstay">
        <div className="stayhosp">
               <img src={require('../../assets/images/stay.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="stay">
            <p2>Stay</p2>
              </div>
        </div> 
             
             
        </div> 

        <div className="hospBandB">
        <div className="BandBhosp">
               <img src={require('../../assets/images/BandB.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="BandB">
            <p2>BandB</p2>
              </div>
        </div> 
             
             
        </div> 

        <div className="hospthreestar">
        <div className="threestarBhosp">
               <img src={require('../../assets/images/3star.jpeg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="threestar">
            <p2>3 Star</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="hospfivestar">
        <div className="fivestarBhosp">
               <img src={require('../../assets/images/5star.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="fivestar">
            <p2>5 Star</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="hospsevenstar">
        <div className="sevenstarBhosp">
               <img src={require('../../assets/images/7star.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="sevenstar">
            <p2>7 Star</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="hospboutique">
        <div className="boutiquehosp">
               <img src={require('../../assets/images/boutique.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="boutique">
            <p2>Boutique</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="hospresorts">
        <div className="resortshosp">
               <img src={require('../../assets/images/resorts.jpeg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="resort">
            <p2>Resort</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="hospesca">
        <div className="escahosp">
               <img src={require('../../assets/images/esca.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="esca">
            <p2>Escapades</p2>
              </div>
        </div> 
                 
        </div> 

               <div className="left image">
      <div className="image5">
        <Link to="/">
          <img src={mainlogo5} alt="Staging Depot1" />{" "}
        </Link>
      </div>
    </div>


    <div className="empty-legs4">
          <div className="container mx-auto">
            <h2>Healthcare</h2>
            <p>A large Inventory of Pharma, Superspeciality Hospitals, Diagnostic Centres,
               Clinics available for rent.</p>
               </div>
               </div>
              
               <div className="healclinic">
        <div className="clinicheal">
               <img src={require('../../assets/images/clinic.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="clinic">
            <p2>Clinics</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="healpharmacli">
        <div className="clipharmaheal">
               <img src={require('../../assets/images/pharmaclinic.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="pharmaclinic">
            <p2>Pharma</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="healpharmacli">
        <div className="clipharmaheal">
               <img src={require('../../assets/images/pharmaclinic.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="pharmaclinic">
            <p2>Pharma</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="healdia">
        <div className="diaheal">
               <img src={require('../../assets/images/diagnostic.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="diagnostic">
            <p2>Diagnostic Center</p2>
              </div>
        </div> 
                 
        </div> 

        <div className="healsuper">
        <div className="superheal">
               <img src={require('../../assets/images/super.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="super">
            <p2>Super Speciality<br></br> Center</p2>
              </div>
        </div> 
                 
        </div>

        <div className="healhosp">
        <div className="hospheal">
               <img src={require('../../assets/images/hospital.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="hosp">
            <p2>Hospitals</p2>
              </div>
        </div> 
                 
        </div>  

        <div className="healcity">
        <div className="cityheal">
               <img src={require('../../assets/images/healthcity.jpg')} alt="google signin" />
        </div> 
               
               <div class="text-block">
               <div class="health">
            <p2>Healthcity</p2>
              </div>
        </div> 
                 
        </div>  
       


               <div className="empty">
          <div className="container mx-auto">
            <h2>Our Brands </h2>
               </div>
               </div>
             
             <div className="left image">
      <div className="Brandlogo">
        <Link to="/">
          <img src={logo} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo1">
        <Link to="/">
          <img src={logo1} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo2">
        <Link to="/">
          <img src={logo2} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo3">
        <Link to="/">
          <img src={logo3} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo4">
        <Link to="/">
          <img src={logo4} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>
       
        <div className="left image">
      <div className="Brandlogo5">
        <Link to="/">
          <img src={logo5} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo6">
        <Link to="/">
          <img src={logo6} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo7">
        <Link to="/">
          <img src={logo7} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

        <div className="left image">
      <div className="Brandlogo8">
        <Link to="/">
          <img src={logo8} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div>

         <div className="left image">
      <div className="Brandlogo9">
        <Link to="/">
          <img src={logo9} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo10">
        <Link to="/">
          <img src={logo10} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

      <div className="left image">
      <div className="Brandlogo11">
        <Link to="/">
          <img src={logo11} alt="Staging Depot1" />{" "}


        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo12">
        <Link to="/">
          <img src={logo12} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 


        <div className="left image">
      <div className="Brandlogo13">
        <Link to="/">
          <img src={logo13} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo14">
        <Link to="/">
          <img src={logo14} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo15">
        <Link to="/">
          <img src={logo15} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 


        <div className="left image">
      <div className="Brandlogo16">
        <Link to="/">
          <img src={logo16} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo17">
        <Link to="/">
          <img src={logo17} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

      
        <div className="left image">
      <div className="Brandlogo18">
        <Link to="/">
          <img src={logo18} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
        <div className="left image">
      <div className="Brandlogo19">
        <Link to="/">
          <img src={logo19} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo20">
        <Link to="/">
          <img src={logo20} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 


        <div className="left image">
      <div className="Brandlogo21">
        <Link to="/">
          <img src={logo21} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
        <div className="left image">
      <div className="Brandlogo22">
        <Link to="/">
          <img src={logo22} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
        <div className="left image">
      <div className="Brandlogo23">
        <Link to="/">
          <img src={logo23} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo24">
        <Link to="/">
          <img src={logo24} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo25">
        <Link to="/">
          <img src={logo25} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
        <div className="left image">
      <div className="Brandlogo26">
        <Link to="/">
          <img src={logo26} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
      
        <div className="left image">
      <div className="Brandlogo26">
        <Link to="/">
          <img src={logo26} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo27">
        <Link to="/">
          <img src={logo27} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo28">
        <Link to="/">
          <img src={logo28} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
        <div className="left image">
      <div className="Brandlogo29">
        <Link to="/">
          <img src={logo29} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo30">
        <Link to="/">
          <img src={logo30} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 

        <div className="left image">
      <div className="Brandlogo31">
        <Link to="/">
          <img src={logo31} alt="Staging Depot1" />{" "}
        </Link>
      </div>
        </div> 
      
      

      {/* <div classname="middle-section">
          <div class name="content img">
            <h1>The Extraordinary Lifestyle </h1>
          </div>
        </div>
        
        
        <div className="right-section">
                    <div className="image-jpg">
                      
                        <img src={image} alt="Staging Depot" />{" "}
                      
                    </div>
          </div> */}
          
        </section>

        {/* Bundles starts */}
         {/* <BundlesHome />*/}
        {/* Bundles starts */}

        {/* Testimonials starts */}
        {/* <Testimonials /> */}
        {/* Testimonials ends */}

        {/* StagingDepotCommitment starts */}
       {/*<StagingDepotCommitment />*/}
        {/* StagingDepotCommitment ends here */}
      </React.Fragment>
    );
  }
}
